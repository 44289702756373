<template>
  <div class="banner"></div>
</template>

<script>
export default {
  name: "AboutBanner"
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/about/banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
