import { render, staticRenderFns } from "./Honor.vue?vue&type=template&id=3b65a00e&scoped=true&"
import script from "./Honor.vue?vue&type=script&lang=js&"
export * from "./Honor.vue?vue&type=script&lang=js&"
import style0 from "./Honor.vue?vue&type=style&index=0&id=3b65a00e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b65a00e",
  null
  
)

export default component.exports