<template>
  <div id="about">
    <div class="anchor-content">
      <AboutBanner></AboutBanner>
    </div>
    <div class="anchor-content">
      <Introduction></Introduction>
    </div>
    <div class="anchor-content">
      <Events></Events>
    </div>
    <div class="anchor-content">
      <Technology></Technology>
    </div>
    <div class="anchor-content">
      <Culture></Culture>
    </div>
    <div class="anchor-content">
      <Honor></Honor>
    </div>
    <div class="anchor-content">
      <Partner></Partner>
    </div>
    <Anchor :anchors="anchors"></Anchor>
  </div>
</template>
<script>
import AboutBanner from "@/components/about/AboutBanner";
import Anchor from "@/components/Anchor";
import Introduction from "@/components/about/Introduction";
import Events from "@/components/about/Events";
import Technology from "@/components/about/Technology";
import Culture from "@/components/about/Culture";
import Honor from "@/components/about/Honor";
import Partner from "@/components/about/Partner";

export default {
  name: 'About',
  components: {
    AboutBanner,
    Introduction,
    Events,
    Anchor,
    Technology,
    Culture,
    Honor,
    Partner
  },
  data() {
    return {
      anchors: {
        count: 7,
        selector: '.anchor-content'
      }
    }
  }
}
</script>
<style scoped lang="scss">
#about {
  height: 100%;
  overflow-y: auto;
  background-color: #121212;
}
</style>
