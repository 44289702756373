<template>
    <div class="introduction">
        <div class="container">
            <h2>公司介绍</h2>
            <p>
                上海曼恒数字技术股份有限公司是一家专注VR、AR、MR技术十五年的高新技术企业，拥有一支稳定、优秀的研发团队，聚焦教育领域、应急安全、高端工业三大行业，在国内和国际的虚拟现实行业中均具有影响力。公司将以创新引领发展，以科技成就收获，成为XR行业的领军企业。
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Introduction',
    }
</script>

<style scoped lang="scss">
    .introduction {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #1f1f1f;

        .container {
            max-width: 1460px;
            padding: 0 20px;
            margin: 160px auto;

            h2 {
                font-family: arial !important;
                font-style: normal;
                font-weight: bold;
                font-size: 48px;
                color: #ffffff;
            }

            p {
                font-family: arial !important;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 238%;
                letter-spacing: 0.08em;
                margin-top: 30px;
                color: #ffffff;
            }
        }
    }
</style>
