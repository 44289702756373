<template>
  <div class="honor">
    <div class="container">
      <h2 class="title">企业荣誉</h2>
      <div class="honors">
        <swiper class="swiper" ref="mySwiper" :options="swiperOption">
          <swiper-slide v-for="(item,index) in honors" :key="index">
            <div class="honor-item">
              <img class="img" :src="item.img" alt="">
              <p class="name">{{item.name}}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Honor",
  data() {
    return {
      swiperOption: {
        grabCursor: true,
        slidesPerView: 7,
        spaceBetween: 45,
        freeMode: true,
        loop: true,
        on: {
          touchStart: () => {
            if (this.myInterval) {
              // console.log(this.mySwiper)
              clearInterval(this.myInterval)
            }
          },
          touchEnd: () => {
            this.startTranslate()
          }
        }
      },
      honors: [
        {
          img: require('../../assets/img/about/honor/honor_kjjr.png'),
          name: '上海科技小巨人企业'
        },
        {
          img: require('../../assets/img/about/honor/honor_zjtx.png'),
          name: '上海“专精特新”企业'
        },
        // {
        //   img: require('../../assets/img/about/honor/2016年度新三板最具创新力奖.png'),
        //   name: '2016年度新三板最具创新力奖'
        // },
        {
          img: require('../../assets/img/about/honor/2017-2018AAA.png'),
          name: '2017-2018合同信用等级AAA'
        },
        {
          img: require('../../assets/img/about/honor/2017zyqyj.png'),
          name: '2017年卓越企业奖'
        },
        {
          img: require('../../assets/img/about/honor/2018byl.png'),
          name: '2018白玉兰杯上海设计创新产品大赛“最佳技术创新奖”'
        },
        {
          img: require('../../assets/img/about/honor/2018shzd.png'),
          name: '2018上海市重点产品质量振兴攻关成果奖'
        },
        {
          img: require('../../assets/img/about/honor/2019dyj.png'),
          name: '2019第一届上海市知识产权创新奖'
        },
        {
          img: require('../../assets/img/about/honor/2020vr50.png'),
          name: '2020年VR50强企业'
        },
        {
          img: require('../../assets/img/about/honor/shsjkj.png'),
          name: '上海市松江区科技进步一等奖'
        },
        {
          img: require('../../assets/img/about/honor/sjgyhb.png'),
          name: '十佳公益伙伴企业'
        },
        {
          img: require('../../assets/img/about/honor/sjqyjszx.png'),
          name: '松江企业技术中心'
        },
        {
          img: require('../../assets/img/about/honor/zjcxlfwjg.png'),
          name: '最具创新力服务机构'
        },
        {
          img: require('../../assets/img/about/honor/zgsw.png'),
          name: '职工书屋'
        },
        // {
        //   img: require('../../assets/img/about/honor/2016年度新三板最具创新能力百强奖.png'),
        //   name: '2016年度新三板最具创新能力百强奖'
        // },
        {
          img: require('../../assets/img/about/honor/2019jhj.png'),
          name: '2019金号角奖'
        },
        {
          img: require('../../assets/img/about/honor/sjmxcp.png'),
          name: '十佳明星产品奖'
        },
      ],
    }
  },
  mounted() {
    this.mySwiper = this.$refs.mySwiper.$swiper;
    this.startTranslate()
  },
  methods: {
    startTranslate() {
      const step = 1
      let index = 1
      let initialTranslate = this.mySwiper.translate <= 0 ? this.mySwiper.translate : 0;
      const contentWidth = this.mySwiper.virtualSize - this.mySwiper.$wrapperEl[0].clientWidth
      this.myInterval = setInterval(() => {
        const translate = -(index * step) + initialTranslate
        // console.log(translate)
        if (Math.abs(translate) >= contentWidth) {
          index = 1
          initialTranslate = 0
          this.mySwiper.translateTo(0, 15)
        } else {
          this.mySwiper.translateTo(translate, 15)
        }
        index += 1
      }, 15)
    }
  },
  destroyed() {
    clearInterval(this.myInterval)
  }
}
</script>

<style scoped lang="scss">
.honor {
  width: 100%;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  padding-bottom: 130px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-family: arial !important;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 84px;
      letter-spacing: 0.08em;
      color: #ffffff;
    }

    .honors {
      margin-top: 80px;
      width: 1460px;

      .honor-item {
        width: auto;
        height: 300px;

        .img {
          height: 170px;
        }

        .name {
          margin-top: 30px;
          font-family: arial !important;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 160%;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
