<template>
  <div class="partner">
    <div class="container">
      <h2 class="title">合作伙伴400+</h2>
      <div class="logos">
        <div class="logo" v-for="(logo,index) in partner" :key="index">
          <div class="logoWrap">
            <img :src="logo.logo" :alt="logo.name">
          </div>
          <!--          <span class="name">{{logo.name}}</span>-->
        </div>
        <div class="logo empty" v-for="(i,index) in 8" :key="index"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partner",
  data() {
    return {
      partner: [
        {
          logo: require('../../assets/img/about/partners/hrzq.svg'),
          name: '华融证券'
        },
        {
          logo: require('../../assets/img/about/partners/htzq.svg'),
          name: '海通证券'
        },
        {
          logo: require('../../assets/img/about/partners/tfzq.svg'),
          name: '天风证券'
        },
        {
          logo: require('../../assets/img/about/partners/zjgs.svg'),
          name: '中金公司'
        },
        {
          logo: require('../../assets/img/about/partners/xyzq.svg'),
          name: '兴业证券'
        },
        // {
        //   logo: require('../../assets/img/about/partners/刚泰控股.svg'),
        //   name: '刚泰控股'
        // },
        {
          logo: require('../../assets/img/about/partners/xhhy.svg'),
          name: '星辉互娱'
        },
        {
          logo: require('../../assets/img/about/partners/shhd.svg'),
          name: '上海恒大'
        },
        {
          logo: require('../../assets/img/about/partners/huawei.svg'),
          name: '华为'
        },
        {
          logo: require('../../assets/img/about/partners/hp.svg'),
          name: '惠普'
        },
        {
          logo: require('../../assets/img/about/partners/lx.svg'),
          name: '联想'
        },
        {
          logo: require('../../assets/img/about/partners/mrgd.svg'),
          name: '迈锐光电'
        },
        {
          logo: require('../../assets/img/about/partners/envisionTEC.svg'),
          name: 'envisionTEC'
        },
        {
          logo: require('../../assets/img/about/partners/DM.svg'),
          name: 'DM'
        },
        {
          logo: require('../../assets/img/about/partners/ROKIT.svg'),
          name: 'ROKIT'
        },
        {
          logo: require('../../assets/img/about/partners/pls.svg'),
          name: '普利生'
        },
        {
          logo: require('../../assets/img/about/partners/sfyz.svg'),
          name: '赛富亚洲'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 160px;
  padding-bottom: 130px;
  background-color: #121212;

  .container {
    width: 1626px;
    @media screen and(max-width: 1500px) {
      width: 1366px;
    }

    .title {
      font-family: arial !important;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 123%;
      color: #ffffff;
    }

    .logos {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 203px;
        padding-top: 60px;
        margin-bottom: 50px;

        .logoWrap {
          width: 100%;
          height: 76px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            transition: all 0.3s;

            &:hover {
              //border-color: rgba(0, 0, 0, .09);
              transform: scale(1.1);
            }
          }
        }


        .name {
          margin-top: 22px;
          color: #ffffff;
        }
      }

      .empty {
        height: 0;
      }
    }
  }
}
</style>
