<template>
    <div class="events">
        <div class="container">
            <div class="event-wrap">
                <div class="swiper">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide
                            class="banner-slide"
                            :style="{ backgroundImage: `url(${slide.img})` }"
                            :class="[`banner-${slide.time}`]"
                            v-for="(slide, index) in timeLineSorted"
                            :key="index"
                        >
                            <div class="des">
                                <h2 class="title">曼恒大事记</h2>
                                <div class="des-item" v-for="(item, index) in slide.des" :key="index">
                                    <!--                  <p class="month">{{item.month}}</p>-->
                                    <p class="detail"><span class="dot"></span>{{ item.detail }}</p>
                                </div>
                            </div>
                            <!--              <div class="slide-content">-->
                            <!--                <div class="slide-img">-->
                            <!--                  &lt;!&ndash;                  <img :src="slide.img" class="slide-img-inner">&ndash;&gt;-->
                            <!--                </div>-->
                            <!--              </div>-->
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="time-line" ref="scroll">
                    <div class="scroll-content">
                        <div
                            class="scroll-item"
                            :class="{ active: activeIndex >= index }"
                            @click="timeLineClick(index)"
                            v-for="(item, index) in timeLineSorted"
                            :key="index"
                        >
                            {{ item.time }}
                        </div>
                        <div class="active-line" :style="{ width: `${activeLineWidth}px` }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BScroll from 'better-scroll'

    export default {
        name: 'Events',
        data() {
            return {
                mySwiper: null,
                activeIndex: 0,
                activeLineWidth: 0,
                timeLine: [
                    {
                        time: 2007,
                        img: require('../../assets/img/about/events/2007.jpg'),
                        des: [
                            {
                                detail: '3月6日上海曼恒数字技术有限公司成立于虹桥路333号交大慧谷',
                            },
                            {
                                detail: '决定投身于虚拟现实事业，成为中国较早的虚拟现实领域创业者之一',
                            },
                        ],
                    },
                    {
                        time: 2008,
                        img: require('../../assets/img/about/events/2008.jpg'),
                        des: [
                            {
                                detail: '推出多通道沉浸式虚拟现实硬件系统，并采用Quest3D工具开发VR内容',
                            },
                            {
                                detail: '签约第一个虚拟现实系统客户（湖南城市学院）',
                            },
                            {
                                detail: '参加首届SIGGRAPH ASIA展会，广泛学习全球VR技术',
                            },
                            {
                                detail: '8月28日全资子公司北京曼恒数字技术有限公司成立',
                            },
                        ],
                    },
                    {
                        time: 2009,
                        img: require('../../assets/img/about/events/2009.jpg'),
                        des: [
                            {
                                detail: '正式推出国内自研CAVE洞穴沉浸式虚拟现实显示系统',
                            },
                            {
                                detail: '签约首个五面CAVE洞穴式沉浸式系统客户（北京建筑工程学院）',
                            },
                            {
                                detail: '公司乔迁至上海市钦州路100号科创中心，成为市科委孵化企业',
                            },
                            {
                                detail: '荣获上海市科学技术学会“最具活力科技企业”称号',
                            },
                        ],
                    },
                    {
                        time: 2010,
                        img: require('../../assets/img/about/events/2010.jpg'),
                        des: [
                            {
                                detail: '公司组建VR引擎研发团队，大力投入虚拟现实核心软件技术与产品的开发',
                            },
                            {
                                detail: '启动自主知识产权的VR工具引擎DVS3D的研发',
                            },
                            {
                                detail: '行业内正式推出虚拟现实标准化沉浸式显示硬件IM系列产品',
                            },
                            {
                                detail: '签约世界500强企业（美国迪斯尼），公司产品及技术得到国际大公司的认可',
                            },
                        ],
                    },
                    {
                        time: 2011,
                        img: require('../../assets/img/about/events/2011.jpg'),
                        des: [
                            {
                                detail: '中华人民共和国副主席，时任上海市长韩正莅临曼恒',
                            },
                            {
                                detail: '荣获上海“高新技术企业”称号',
                            },
                            {
                                detail: '创始人周清会荣获第十届“上海IT青年十大新锐”称号',
                            },
                            {
                                detail: '签约首个能源行业客户（中广核集团），为其提供虚拟现实硬件与内容开发服务',
                            },
                        ],
                    },
                    {
                        time: 2012,
                        img: require('../../assets/img/about/events/2012.jpg'),
                        des: [
                            {
                                detail: '启动自主知识产权的红外光学追踪系统G-Motion V1.0的研发',
                            },
                            {
                                detail: '推出了G-MAGIC V1.0变形沉浸式虚拟现实硬件产品',
                            },
                            {
                                detail: '签约首个军工领域的虚拟现实客户',
                            },
                            {
                                detail: '荣获“上海市设计创新示范企业”称号',
                            },
                        ],
                    },
                    {
                        time: 2013,
                        img: require('../../assets/img/about/events/2013.jpg'),
                        des: [
                            {
                                detail: '获得首份超过千万的合同-中国航发集团航空发动机虚拟设计系统',
                            },
                            {
                                detail: '启用上海合川路2679号办公室，公司进入规模化的发展阶段',
                            },
                            {
                                detail: '公司获得相卫集团3500万的融资',
                            },
                        ],
                    },
                    {
                        time: 2014,
                        img: require('../../assets/img/about/events/2014.jpg'),
                        des: [
                            {
                                detail: '行业内正式推出3D-LED沉浸式虚拟现实显示交互产品',
                            },
                            {
                                detail: '公司启动3D打印业务战略，并投资入股3D打印公司(上海普利生)',
                            },
                            {
                                detail: '入选智联招聘“2014中国年度最佳雇主30强”榜单',
                            },
                        ],
                    },
                    {
                        time: 2015,
                        img: require('../../assets/img/about/events/2015.jpg'),
                        des: [
                            {
                                detail: '联合普利生推出锐打RP400面阵曝光技术3D打印机',
                            },
                            {
                                detail: '完成广州、成都、武汉、沈阳、西安、济南等分支机构区域布局',
                            },
                            {
                                detail: '公司获得欧德、湖杉资本2404万融资',
                            },
                            {
                                detail:
                                    '公司完成股改，华融证券、海通证券、天风证券等成为首批做市商，融资4730万',
                            },
                        ],
                    },
                    {
                        time: 2016,
                        img: require('../../assets/img/about/events/2016.jpg'),
                        des: [
                            {
                                detail: 'DVS3D升级为IdeaVR，率先推出行业内异地多人协同VR工具引擎',
                            },
                            {
                                detail: '推出VRDO虚拟现实工业可视化工具引擎',
                            },
                            {
                                detail: '推出G-Motion全身动捕交互追踪产品',
                            },
                            {
                                detail:
                                    '公司获得赛富亚洲、中金公司和兴业证券等多家知名投资机构和星辉娱乐、爱仕达等上市公司2.05亿的融资',
                            },
                        ],
                    },
                    {
                        time: 2017,
                        img: require('../../assets/img/about/events/2017.jpg'),
                        des: [
                            {
                                detail: '明确VR内容平台战略，并推出VRBOX虚拟现实内容平台',
                            },
                            {
                                detail: '与德国EnvisionTEC合作，推出生物3D打印业务',
                            },
                            {
                                detail: '曼恒参与并保障的国产大飞机C919首飞试飞系统备受肯定，获得中国商飞的感谢信，周清会获得个人荣誉证书与勋章',
                            },
                            {
                                detail: '曼恒作为技术单位参与2017年度国家重点研发计划“公共安全风险防控与应急技术装备”专项',
                            },
                        ],
                    },
                    {
                        time: 2018,
                        img: require('../../assets/img/about/events/2018.jpg'),
                        des: [
                            {
                                detail: '中央政治局常委，国务院总理，时任上海市委书记李强莅临曼恒',
                            },
                            {
                                detail: '公司被认定为“上海虚拟仿真教学工程技术研究中心”',
                            },
                            {
                                detail: '国内率先推出VR内容适配软件LinkVR V1.0',
                            },
                            {
                                detail: '签约世界500强企业（美国艾默生电气公司），公司业务拓展到智能制造领域',
                            },
                            {
                                detail: '荣获2018年度公益之申“十佳公益伙伴企业”称号',
                            },
                        ],
                    },
                    {
                        time: 2019,
                        img: require('../../assets/img/about/events/2019.jpg'),
                        des: [
                            {
                                detail: '成为华为5G+VR的行业战略合作伙伴，并联合推出行业内领先的5G串流软件FlyVR(飞流)',
                            },
                            {
                                detail: '推出行业内较早VR智能中控系统（八爪鱼）',
                            },
                            {
                                detail: '中国慕课大会指定VR软件及内容支持企业，获得教育部感谢信',
                            },
                            {
                                detail: '入选2019年世界VR产业大会“中国VR50强企业”榜单',
                            },
                        ],
                    },
                    {
                        time: 2020,
                        img: require('../../assets/img/about/events/2020.jpg'),
                        des: [
                            {
                                detail: '发布新一代VR引擎IdeaVR 2021(阿凡达)',
                            },
                            {
                                detail: '推出新一代VR大空间解决方案（小蜜蜂）',
                            },
                            {
                                detail: '公司搬迁至顺庆路500号河图公园，打造VR产业链生态的创新地',
                            },
                            {
                                detail: '第二届全国电子信息行业新技术应用职业技能竞赛-“曼恒杯”VR技术应用大赛圆满收官'
                            },
                            {
                                detail: '参与“国家危险化学品应急救援实训演练（大庆）基地”建设'
                            }
                        ],
                    },
                    {
                        time: 2021,
                        img: require('../../assets/img/about/events/2021.jpg'),
                        des: [
                            {
                                detail: '2021年全国职业院校技能大赛虚拟现实（VR）制作与应用赛项（中职组）圆满举办',
                            },
                            {
                                detail: '打造新一代工程技术领域VR可视化工具VRDO2021(大黄蜂）',
                            },
                            {
                                detail: '发布首款VR一体机·7光',
                            },
                            {
                                detail: '成为第一届全国医学虚拟仿真实验创新大赛技术支持单位'
                            }
                        ],
                    },
                    {
                        time: 2022,
                        img: require('../../assets/img/about/events/2022.jpg'),
                        des: [
                            {
                                detail: '曼恒实现了北京、上海双总部布局',
                            },
                            {
                                detail: '成为全国新职业和数字技术技能大赛技术支持单位',
                            },
                            {
                                detail: '成为第46届世界技能大赛技术展项企业之一',
                            }
                        ],
                    },
                ],
                swiperOptions: {
                    initialSlide: 4,
                    spaceBetween: 220,
                    on: {
                        slideChangeTransitionEnd: () => {
                            if (this.mySwiper) {
                                this.activeIndex = this.mySwiper.activeIndex
                                this.timeLineClick(this.activeIndex)
                            }
                        },
                    },
                },
            }
        },
        computed: {
            timeLineSorted() {
                return this.timeLine.concat([]).sort((a, b) => a.time - b.time)
            },
        },
        mounted() {
            this.initBs()
            this.$nextTick(() => {
                this.mySwiper = this.$refs.mySwiper.$swiper
                this.timeLineClick(this.activeIndex)
            })
        },
        methods: {
            initBs() {
                this.bs = new BScroll(this.$refs.scroll, {
                    scrollX: true,
                })
            },
            timeLineClick(index) {
                this.activeIndex = index
                const scrollItems = document.querySelectorAll('.scroll-item')
                const activeScrollItems = Array.from(scrollItems).slice(0, index + 1)
                let activeLineWidth = 0
                let lastHalfWidth = 0
                activeScrollItems.forEach((item) => {
                    activeLineWidth += item.clientWidth
                    // lastHalfWidth = item.clientWidth / 2
                    lastHalfWidth = item.clientWidth / 4
                })
                this.activeLineWidth = activeLineWidth - lastHalfWidth
                this.mySwiper.slideTo(index)
            },
        },
        beforeDestroy() {
            this.bs.destroy()
        },
    }
</script>

<style scoped lang="scss">
    .events {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        //padding-bottom: 80px;
        height: 973px;
        background-color: #121212;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        @media screen and(max-width: 1600px) {
            height: 700px;
        }

        .container {
            width: 100%;
            height: 100%;
            //@media screen and(max-width: 1460px) {
            //  width: 1366px;
            //  padding: 0 15px;
            //}

            .event-wrap {
                width: 100%;
                height: 100%;
                //margin-top: 36px;
                //display: flex;
                //flex-direction: column;
                position: relative;

                .time-line {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 80px;
                    //flex: none;
                    /*width: 1477px;*/
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: left;
                    margin: 0 auto;
                    z-index: 2;
                    @media screen and (max-width: 1460px) {
                        width: 1366px;
                        bottom: 30px;
                    }

                    .scroll-content {
                        display: inline-block;
                        text-align: left;
                        border-bottom: 1px solid #535353;
                        position: relative;

                        .scroll-item {
                            padding: 24px 34px;
                            display: inline-block;
                            font-family: arial !important;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            cursor: pointer;
                            transition: all 0.2s;
                            color: #ffffff;
                            @media screen and (max-width: 1460px) {
                                padding: 24px 25px;
                            }

                            &:first-child {
                                padding-left: 0;
                            }

                            &:last-child {
                                padding-right: 0;
                            }

                            &.active {
                                color: #f89227;
                            }
                        }

                        .active-line {
                            position: absolute;
                            width: 0;
                            height: 1px;
                            background-color: #f89227;
                            z-index: 2;
                            transition: all 0.2s;
                        }
                    }
                }

                .swiper {
                    width: 100%;
                    height: 100%;

                    .swiper-container {
                        height: 100%;
                    }

                    .banner-slide {
                        background-repeat: no-repeat;
                        //background-position: center;
                        background-size: 100%;

                        .des {
                            flex-wrap: wrap;
                            height: 480px;
                            overflow-y: auto;
                            text-align: justify;

                            /* width */
                            &::-webkit-scrollbar {
                                width: 4px;
                                height: 4px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #605f5f;
                                border-radius: 8px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #c4c0c0;
                                border-radius: 8px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: rgb(162, 162, 163);
                            }

                            .title {
                                font-family: arial !important;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 48px;
                                //line-height: 84px;
                                letter-spacing: -0.025em;
                                //margin-top: 120px;
                                margin-left: 20px;
                                color: #ffffff;
                                margin-bottom: 25px;
                            }

                            .des-item {
                                margin-bottom: 25px;
                                font-family: arial !important;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 30px;
                                //padding-right: 4px;
                                //letter-spacing: 1px;
                                color: #ffffff;
                                text-align: justify;

                                .month {
                                    font-weight: bold;
                                    color: #ffffff;
                                }

                                .detail {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: baseline;

                                    .dot {
                                        flex: none;
                                        width: 8px;
                                        height: 8px;
                                        background: #f89227;
                                        //border-radius: 50%;
                                        margin-right: 14px;
                                        position: relative;
                                        top: -2px;
                                    }
                                }
                            }
                        }

                        &.banner-2007 {
                            .des {
                                position: relative;
                                top: 45.95%;
                                left: 50.15%;
                                width: 725px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 35%;
                                }
                            }
                        }

                        &.banner-2008 {
                            .des {
                                position: relative;
                                top: 31.96%;
                                left: 20.31%;
                                width: 725px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 28%;
                                    left: 15%;
                                }
                            }
                        }

                        &.banner-2009 {
                            .des {
                                position: relative;
                                top: 21.68%;
                                left: 56.51%;
                                width: 725px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                }
                            }
                        }

                        &.banner-2010 {
                            .des {
                                position: relative;
                                top: 28.98%;
                                left: 14.27%;
                                width: 725px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 20%;
                                    left: 10%;
                                }
                            }
                        }

                        &.banner-2011 {
                            .des {
                                position: relative;
                                top: 31.51%;
                                left: 55.2%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 25%;
                                }
                            }
                        }

                        &.banner-2012 {
                            padding-bottom: 30px;
                            .des {
                                position: relative;
                                top: 24.56%;
                                left: 15.57%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    left: 10%;
                                }
                            }
                        }

                        &.banner-2013 {
                            .des {
                                position: relative;
                                top: 44.91%;
                                left: 54.58%;
                                width: 554px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 40%;
                                }
                            }
                        }

                        &.banner-2014 {
                            .des {
                                position: relative;
                                top: 16.95%;
                                left: 14.27%;
                                width: 554px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    left: 8%;
                                }
                            }
                        }

                        &.banner-2015 {
                            .des {
                                position: relative;
                                top: 28.68%;
                                left: 52.81%;
                                width: 651px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 20%;
                                }
                            }
                        }

                        &.banner-2016 {
                            .des {
                                position: relative;
                                top: 24.04%;
                                left: 55%;
                                width: 606px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 20%;
                                }
                            }
                        }

                        &.banner-2017 {
                            .des {
                                position: relative;
                                top: 26.2%;
                                left: 14.27%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                }
                            }
                        }

                        &.banner-2018 {
                            .des {
                                position: relative;
                                top: 26.61%;
                                left: 14.27%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                }
                            }
                        }

                        &.banner-2019 {
                            .des {
                                position: relative;
                                top: 31.78%;
                                left: 55.83%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 25%;
                                }
                            }
                        }

                        &.banner-2020 {
                            .des {
                                position: relative;
                                top: 30.77%;
                                left: 5.2%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 20%;
                                }
                            }
                        }

                        &.banner-2021 {
                            .des {
                                position: relative;
                                top: 24.04%;
                                left: 55%;
                                width: 606px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 20%;
                                }
                            }
                        }

                        &.banner-2022 {
                            .des {
                                position: relative;
                                top: 30.77%;
                                left: 10.2%;
                                width: 528px;
                                @media screen and(max-width: 1600px) {
                                    width: 500px;
                                    top: 45%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
