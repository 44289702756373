<template>
    <div class="technology">
        <div class="container">
            <h2 class="title">全产业链自主核心技术</h2>
            <p class="des">
                曼恒坚持自主创新，建立起内容、软件和硬件的统一标准，拥有软件引擎、内容平台、交互追踪和 <br />
                显示硬件等关键技术的自主知识产权。
            </p>
            <div class="circles">
                <div class="circle" v-for="(item, index) in circles" :key="index">
                    <p class="num">{{ item.num }}+</p>
                    <p class="line"></p>
                    <p class="name">{{ item.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Technology',
        data() {
            return {
                circles: [
                    {
                        num: 80,
                        name: '专利',
                    },
                    {
                        num: 3800,
                        name: '内容产品',
                    },
                    {
                        num: 2000,
                        name: '服务客户',
                    },
                    {
                        num: 100,
                        name: '渠道伙伴',
                    },
                    {
                        num: 210,
                        name: '软件著作权',
                    },
                ],
            }
        },
    }
</script>

<style scoped lang="scss">
    .technology {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #2a2a2a;
        padding: 120px 0 160px 0;

        .container {
            width: 1460px;
            @media screen and(max-width: 1460px) {
                width: 1366px;
            }

            .title {
                font-family: arial !important;
                font-style: normal;
                font-weight: bold;
                font-size: 48px;
                color: #ffffff;
            }

            .des {
                font-family: arial !important;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 180%;
                letter-spacing: 0.08em;
                color: #ffffff;
                margin-top: 30px;
            }

            .circles {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-top: 70px;

                .circle {
                    width: 200px;
                    height: 200px;
                    border: 3px solid #ffffff;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .num {
                        font-family: Impact;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 49px;
                        text-align: center;
                        letter-spacing: 0.005em;
                        color: #ffffff;
                    }

                    .line {
                        width: 80%;
                        height: 1px;
                        background-color: #ffffff;
                        margin: 12px 0;
                    }

                    .name {
                        font-family: arial !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 27px;
                        text-align: center;
                        letter-spacing: 0.005em;
                        color: #ffffff;
                    }
                }
            }
        }
    }
</style>
