<template>
  <div class="culture">
    <div class="container">
      <h2 class="title">向上 守正</h2>
      <h3 class="subtitle">我们的企业文化</h3>
      <div class="culture-items">
        <div class="culture-item" v-for="(item,index) in cultures" :key="index">
          <div class="content">
            <img class="icon" :src="item.icon" alt="">
            <p class="name">{{item.name}}</p>
            <p class="detail">{{item.detail}}</p>
          </div>
          <div class="bg" :style="{backgroundImage:`url(${item.img})`}"></div>
<!--          <div class="mask"></div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Culture",
  data() {
    return {
      cultures: [
        {
          icon: require('../../assets/img/about/timer.png'),
          img: require('../../assets/img/about/timerBg.jpg'),
          name: '奉献精神',
          detail: '团队之间富有凝聚力，能够与其他团队相互配合，共同达成目标,勇于承担责任，主动迎接新的任务和挑战，乐于分享专业知识与工作经验，与同事、公司共同成长'
        },
        {
          icon: require('../../assets/img/about/champion.png'),
          img: require('../../assets/img/about/championBg.jpg'),
          name: '追求成就',
          detail: '富有理想和抱负，保持好奇心，不断学习，拥抱变革，直面挑战，保持激情面对内外部的压力和挑战都要做到极致'
        },
        {
          icon: require('../../assets/img/about/target.png'),
          img: require('../../assets/img/about/targetBg.jpg'),
          name: '使命必达',
          detail: '脚踏实地，勤勤恳恳，兢兢业业克服障碍和困难，坚持到底，敢于突破，勇于尝试，不惧失败'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.culture {
  width: 100%;
  background-color: #121212;
  padding: 120px 0;

  .container {
    width: 1460px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and(max-width: 1460px) {
      width: 1366px;
    }

    .title {
      font-family: arial !important;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 84px;
      color: #ffffff;
    }

    .subtitle {
      font-family: arial !important;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      //line-height: 180%;
      color: #ffffff;
      margin-top: 15px;
    }

    .culture-items {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 70px;

      .culture-item {
        width: 460px;
        height: 544px;
        position: relative;
        transition: all 0.2s;
        @media screen and(max-width: 1460px) {
          width: 400px;
          height: 500px;
        }
        &:hover {
          transform: scale(0.95);
        }

        .bg {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          z-index: 1;
        }

        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url("../../assets/img/about/mask.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          z-index: 2;
        }

        .content {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 3;

          .icon {
            margin-top: 116px;
          }

          .name {
            margin-top: 15px;
            font-family: arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #FFFFFF;
          }

          .detail {
            font-family: arial !important;
            font-style: normal;
            //font-weight: bold;
            font-size: 18px;
            line-height: 28px;
            /* or 36px */
            text-align: center;
            letter-spacing: 0.08em;
            color: #FFFFFF;
            margin-top: 18px;
            padding: 0 44px;
          }
        }
      }
    }
  }
}
</style>
